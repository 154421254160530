<template>
  <erp-s-field
      :view="this.fieldView || 'tl'"
      :label="label || 'Comarca'"
      :no-label="noLabel"
      :helper="helper"
      :helper-position="helperPosition || 'lb'"
      :error="error"
      :label-width="labelWidth"
      :style="fieldStyle"
  >
    <u-select
        @input="__set"
        :loading="loading"
        :multiple="multiple"
        :placeholder="!selectNull ? placeholder || 'Qualquer' : ''"
        :options="status"
        v-model="model"
        propoverClass="select-status-def1"
        :disable="disable || !list"
        :style="inputStyle"
        :simple-border="simpleBorder"
        :size="size || '1'"
    >
    </u-select>
  </erp-s-field>
</template>

<script>
import SelectMixin from '../../../../../mixins/SelectMixin'
import {list} from '@/domain/processos/services/comarca'
import {USelect} from "uloc-vue"

export default {
  name: 'ModernComarcaSelect',
  mixins: [SelectMixin],
  props: {
    selectNull: {
      type: Boolean,
      default: false
    },
    tribunal: {
      required: true,
      default: null
    },
    limit: {
      required: false,
      default: () => 1000
    }
  },
  data () {
    return {
      loading: false,
      list: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.load()
    })
  },
  computed: {
    status () {
      const s = []
      this.selectNull && s.push({
        label: this.placeholder || 'Qualquer',
        value: null
      })
      if (this.list && this.list.length) {
        this.list.map(status => {
          s.push({
            ...status,
            label: status.nome,
            value: status.id
          })
        })
      }
      return s
    }
  },
  watch: {
    tribunal (v) {
      this.$nextTick(() => {
        this.load()
      })
    }
  },
  components: {
    USelect
  },
  methods: {
    load () {
      if (!this.tribunal) return
      this.loading = true
      list(this.limit, 1, '&tribunal=' + this.tribunal)
          .then(({data}) => {
            this.list = data.result || data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    __updateInternal () {
      this.$nextTick(() => {
        if (this.value !== 'undefined' && this.value !== null && this.model === null) {
          let _cp = JSON.stringify(this.value) // force to update datepicker with actual value
          this.model = ''
          setTimeout(() => {
            this.model = JSON.parse(_cp)
          }, 1)
        }
      })
    }
  }
}
</script>

