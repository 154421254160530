<template>
  <div>
    <rel-header>
      <form>
        <e-row mr>
          <e-col>
            <e-row class="large-mr erp-s-field-bold" mr>
              <e-col style="max-width: 200px">
                <erp-s-field label="Processo:">
                  <u-input v-model="filters.processo" v-mask="'#######-##.####.#.##.####'" />
                </erp-s-field>
              </e-col>
              <e-col>
                <modern-tribunal-select v-model="filters.tribunal" />
              </e-col>
              <e-col>
                <modern-comarca-select :tribunal="filters.tribunal" limit="10000" v-model="filters.comarca" />
              </e-col>
              <e-col>
                <modern-vara-select :comarca="filters.comarca" v-model="filters.vara" />
              </e-col>
             
              <e-col style="max-width: 200px">
                <erp-s-field label="Cliente:">
                  <global-pessoa-input v-model="filters.cliente" layout="float-icon input-hide-loading"
                    :hide-underline="false" />
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row class="m-t large-mr erp-s-field-bold" mr>
              <e-col  style="max-width: 200px">
                  <modern-tipo-etapa-produtividade-select label="Data de conclusão de:" v-model="filters.tipoProdutividade" />
              </e-col>
              <e-col style="max-width: 140px">
                <erp-s-field label="Data inicial:">
                  <datetime-modern-input v-model="filters.data1" date-type="date" date-format="##/##/####" />
                </erp-s-field>
              </e-col>
              <e-col style="max-width: 140px">
                <erp-s-field label="Data final:">
                  <datetime-modern-input v-model="filters.data2" date-type="date" date-format="##/##/####" />
                </erp-s-field>
              </e-col>
              <e-col style="max-width: 120px">
                <erp-s-field label="Status etapa:">
                  <u-select  :options="[{label: 'Todos', value: null},{label: 'Concluída', value: '1'},{label: 'Não Concluída', value: '0'}]" class="app-input" hide-underline
                  v-model="filters.statusEtapa" />
                </erp-s-field>
              </e-col>
              <e-col style="max-width: 100px">
                <erp-s-field label="Pagamento:">
                  <u-select :options="[{label: 'Todos', value: null},{label: 'Não Pago', value: '0'},{label: 'Pago', value: '1'}]" class="app-input" hide-underline
                  v-model="filters.pago" />
                </erp-s-field>
              </e-col>
              <e-col style="max-width: 100px">
                <erp-s-field label="Com responsável:">
                  <u-select :options="[{label: 'Todos', value: null},{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline
                  v-model="filters.hasResponsavel" />
                </erp-s-field>
              </e-col>
              <e-col >
                <erp-s-field label="Nome Responsável:">
                  <global-pessoa-input v-model="filters.responsavel" layout="float-icon input-hide-loading"
                    :hide-underline="false" />
                </erp-s-field>
              </e-col>
            </e-row>
          </e-col>
          <e-col style="max-width: 120px; text-align: right;" class="flex items-center justify-end">
            <erp-s-field label="">
              <u-btn label="Gerar" color="green" :loading="gerando" @click="() => gerar()" />
            </erp-s-field>
          </e-col>
        </e-row>
      </form>
    </rel-header>
    <div class="sl-body">
      <div class="base-table">
        <table>
          <thead>
            <tr>
              <th>Processo</th>
              <th>Comarca / Vara</th>
              <th>Cliente</th>
              <th v-for="etapa in etapas">{{etapa.nome}}</th>
            </tr>
          </thead>
          <tbody style="border-top: 2px solid #80808063; border-bottom: 30px solid transparent" v-for="produtividade in data">
            <tr>
              <td>{{ produtividade.processo.numero | formataNumeroProcesso }}</td>
              <td><span v-if="produtividade.processo.vara">
                  {{ produtividade.processo.vara.comarca?.uf }} / {{ produtividade.processo.vara.comarca?.nome }} /
                  {{ produtividade.processo.vara.nome }}
                </span>
              </td>
              <td>{{ produtividade.processo.cliente?.name}}</td>
              <td v-for="etapa in etapas">
                <span style="font-weight: bold; color: green" v-if="isConcluido(etapa.id, produtividade)">
                  sim
                </span>
                <span v-else style="font-weight: bold; color: red">não</span>
              </td>
            </tr>
            <tr>
              <td colspan="100">
                <div class="base-table" style="color: #646464 !important">
                  <table>
                    <thead>
                      <tr>
                        <th width="34">
                          <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="0.5" x2="0.5" y2="31" stroke="#676767" />
                            <path
                              d="M28.0395 30.5593C28.0395 32.0321 29.2334 33.226 30.7062 33.226C32.1789 33.226 33.3728 32.0321 33.3728 30.5593C33.3728 29.0866 32.1789 27.8927 30.7062 27.8927C29.2334 27.8927 28.0395 29.0866 28.0395 30.5593ZM0 31.0593H30.7062V30.0593H0V31.0593Z"
                              fill="#676767" />
                          </svg>
                        </th>
                        <th>Responsável</th>
                        <th>Etapa(s)</th>
                        <th style="width: 70px !important">Pagamento(s)</th>
                        <th style="width: 130px !important">Total Pago</th>
                        <th style="width: 130px !important">Não pago</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr  v-for="responsavel in produtividade.responsaveis">
                        <td></td>
                        <td>{{ responsavel.name }}</td>
                        <td>{{responsavel.etapasNomes.join(', ')}}</td>
                        <td>{{responsavel.countPagamentos}}</td>
                        <td>R$ {{responsavel.totalPago | moeda}}</td>
                        <td style="color: red">R$ {{responsavel.totalNaoPago | moeda}}</td>
                      </tr>
                      <tr v-if="produtividade.responsaveis.length == 0">
                        <td colspan="100" style="color: orange">
                          Sem responsáveis
                        </td>
                      </tr>
                    </tbody>
                    <tfoot v-if="produtividade.responsaveis.length > 0">
                      <tr>
                        <th colspan="2"></th>
                        <th  style="width: 300px !important">Total  pago</th>
                        <td style="width: 130px !important">R$ {{produtividade.totalPago | moeda}}</td>
                        <th v-if="+produtividade.totalNaoPago > 0">Total não pago</th>
                        <td v-if="+produtividade.totalNaoPago > 0" style="width: 130px !important; color: red">R$ {{produtividade.totalNaoPago | moeda}}</td>
                        <td v-else style="width: 130px !important; color: gree">R$ {{produtividade.totalNaoPago | moeda}}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import RelHeader from '@/components/relatorios/layout/RelHeader'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import { ErpSField } from "uloc-vue-plugin-erp"
import { UInput, USelect } from "uloc-vue"
import DatetimeModernInput from "@/reuse/input/DatetimeModern"
import GlobalPessoaInput from "@/components/pessoa/components/include/pessoa/PessoaInput"
import { relatorio } from "@/domain/relatorios/services"
import { datePtToEn } from "@/utils/date"
import ModernTribunalSelect from "@/components/processos/components/processos/include/ModernTribunalSelect"
import ModernVaraSelect from "@/components/processos/components/processos/include/ModernVaraSelect"
import ModernComarcaSelect from "@/components/processos/components/processos/include/ModernComarcaSelect"
import ModernTipoEtapaProdutividadeSelect from "@/components/processos/components/processos/include/ModernTipoEtapaProdutividadeSelect"

export default {
  name: "Visualizacao",
  data() {
    return {
      cache: {
        timestamp: null
      },
      fake: {
      },
      data: [],
      totalProcessos: '0.00',
      etapas: [],
      filters: {
        data1: null,
        data2: null,
        processo: null,
        responsavel: null,
        cliente: null,
        tribunal: null,
        comarca: null,
        vara: null,
        tipoProdutividade: null,
        statusEtapa: null,
        pago: null,
        hasResponsavel: null,
      },
      gerando: false
    }
  },
  mounted() {
  },
  computed: {
   
  },
  methods: {
    gerar(format = 'json') {
      const filters = JSON.parse(JSON.stringify(this.filters))
      filters.data1 = filters.data1 ? datePtToEn(filters.data1) : null
      filters.data2 = filters.data2 ? datePtToEn(filters.data2) : null
      filters.responsavel = filters.responsavel && filters.responsavel.id ? filters.responsavel.id : null
      filters.cliente = filters.cliente && filters.cliente.id ? filters.cliente.id : null
      this.$uloc.loading.show({ message: 'Gerando relatório' })
      this.gerando = true
      relatorio('produtividade', filters, format)
        .then(response => {
          this.$uloc.loading.hide()
          if (response) {
            this.data = response.data.result
            this.etapas = response.data.tipos_etapa
            this.totalProcessos = response.data.totalProcessos
          }
          this.gerando = false
        })
        .catch(error => {
          this.gerando = false
          this.$uloc.loading.hide()
          this.alertApiError(error)
        })
    },
    hasIndicacao(etapa1) {
      return etapa1.intimacoes?.length > 0
    },
    isConcluido(id, produtividade) {
      return !!produtividade.etapas.find(e => e.tipo.id == id)?.dataConclusao
    },
   
  },
  components: {
    ModernComarcaSelect,
    ModernVaraSelect,
    ModernTribunalSelect,
    ModernTipoEtapaProdutividadeSelect,
    GlobalPessoaInput,
    DatetimeModernInput,
    ErpSField,
    ECol,
    ERow,
    RelHeader,
    UInput,
    USelect
  }
}
</script>
